import "bootstrap/dist/css/bootstrap.min.css"
import React from "react"
import { Row, Col } from "reactstrap"
import { StaticImage } from "gatsby-plugin-image"

const LeadsHeader = ({ title, scrollDown }) => {
  const handleScroll = () => {
    scrollDown.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
  }

  return (
    <div className="container">
      <Row className="px-xs-2 px-md-5">
        <Col xs={12} lg={7} className="d-flex align-items-end mt-2">
          <h1 className="font-weight-bolder handling-heading mb-0">{title}</h1>
        </Col>
        <Col xs={12} lg={7}>
          <div className="arrow d-none d-lg-block">
            <h4 className="text-white">FILL OUT A QUICK 2 MINUTE FORM</h4>
          </div>
          <div className="d-flex flex-column align-items-center">
            <div className="p-3 mt-3 arrow-down d-lg-none">
              <h3 className="text-white text-center">
                FILL OUT A QUICK 2 MINUTE FORM
              </h3>
            </div>
          </div>
          <div className="mb-3">
            <button
              className="scroll-link font-weight-bold text-color-secondary btn"
              onClick={handleScroll}
            >
              Learn more
            </button>
          </div>
        </Col>
        <Col
          xs={12}
          lg={4}
          className="my-5 d-flex d-lg-block justify-content-center"
        >
          <div id="bzWidgetContainer" />
        </Col>

        <Col xs={12} lg={7}>
          <Row>
            <Col xs={11} className="d-flex align-items-center">
              <StaticImage src="../images/book.png" style={{ width: 45 }} />
              <h5 className="mx-3">
                Compare Multiple Rates, so you know you’re getting the best
                deal.
              </h5>
            </Col>
            <Col xs={11} className="pt-3 d-flex align-items-center">
              <StaticImage src="../images/document.png" style={{ width: 45 }} />
              <h5 className="mx-3">
                Compare quotes on various types of medical software to help your
                business grow.
              </h5>
            </Col>
          </Row>
          <Row className="d-none d-lg-block">
            <Col xs={12} md={11} lg={12} className="d-flex justify-content-end">
              <StaticImage
                src="../images/guide-arrow.png"
                style={{ width: 135 }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default LeadsHeader
