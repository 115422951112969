import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import "../components/categoryform.css"
import "../components/multistepform/Muliform.css"
import Mainfooter from "../components/mainfooter"
import HowWorks from "../components/howItWorks/index"
import Header2 from "../components/header2"
import Seo from "../components/seo"
import LeadsHeader from "../components/leadsHeader"
import Accordion from "../components/accordion"

const Cateform = ({
  data: {
    markdownRemark: {
      frontmatter: { title, featuredImage, articleHeading, categoryName } = {},
      html,
    } = {},
  },
} = {}) => {
  const displayImage = getImage(featuredImage)

  useEffect(() => {
    document.getElementById("bzWidgetContainer").innerHTML = ""
    loadBZ()
    loadRedTrack()
    loadFraudBlocker()
  }, [])
  const ref = React.createRef()

  const [collapse, setCollapse] = useState(false)

  const loadBZ = () => {
    const script = document.createElement("script")
    script.src = "https://chameleon-frontend-na.mvfglobal.com/formLoader.min.js"
    script.async = true
    script.onload = () => {
      document.getElementById("bzWidgetContainer").appendChild(scriptInner)
    }
    const scriptInner = document.createElement("script")
    scriptInner.append(`
      var inputData = {
        domain: 'na',
        formId: '6796',
        dynamicHeight: true,
        campaignId: '6627a7850eb32',
        height: 450,
        themeName: 'chameleon',
        eventHandlers: {
          initialWidgetLoad: function (eventInfo) {
              eventInfo.event = "initialWidgetLoad";
              window?.dataLayer?.push(eventInfo);
          },
          pageChanged: function (eventInfo) {
              eventInfo.event = "pageChanged";
              window?.dataLayer?.push(eventInfo);
          },
          questionAnswered: function (eventInfo) {
              eventInfo.event = "questionAnswered";
              window?.dataLayer?.push(eventInfo);
          },
          finalSubmission: function (eventInfo) {
            console.log("Final submission made by Expert Market")
            let event={}
            event.event = 'conversionSM'; // Set event name
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(event); // Push to data layer
          },
          formError: function (errorInfo) {
              errorInfo.event = "formError";
              window?.dataLayer?.push(errorInfo);
          },
      },
      };
      var formWidgetInfoObject = runFormWidgetLoader(inputData);
    `)
    document.getElementById("bzWidgetContainer").appendChild(script)
  }

  const loadRedTrack = () => {
    let params = new URLSearchParams(window.location.search)
    if (params.get("rtClickID")) {
      setCookie("rtkclickid-store", params.get("rtClickID"), 1)
    } else {
      loadUniversalScript()
    }
  }

  const loadFraudBlocker = () => {
    var s = document.createElement("script"),
      h = document.head
    s.async = 1
    s.src = "https://monitor.fraudblocker.com/fbt.js?sid=6lGaOIKvycQnQncyWXCzh"
    h.appendChild(s)
  }

  const loadUniversalScript = () => {
    const script = document.createElement("script")
    script.id = "__script__id__"
    script.type = "text/javascript"
    script.async = true
    script.src =
      "https://track.imgtrx2.com/uniclick.js?defaultcampaignid=6350d7cad7dd530001e5af93&attribution=lastpaid&regviewonce=false&cookiedomain=&cookieduration=30"
    document.body.append(script)
  }

  const setCookie = (name, value) => {
    var expires = ""
    var date = new Date()
    date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000)
    expires = "; expires=" + date.toUTCString()
    document.cookie = name + "=" + (value || "") + expires + "; path=/"
  }

  const scrollandCollapse = collapseValue => {
    setCollapse(collapseValue)
  }

  const scrollDown = ref
  return (
    <>
      <Seo title={categoryName} />
      <Header2 />

      <LeadsHeader scrollDown={scrollDown} title={title} />

      <div style={{ marginTop: -20 }} className="row d-none d-lg-block mb-5">
        <div className="col-xs-8 pt-3 pb-5 d-flex justify-content-center">
          <GatsbyImage image={displayImage} style={{ marginLeft: -500 }} />
        </div>
      </div>
      <HowWorks />
      <div ref={scrollDown}>
        <Accordion
          scrollandCollapse={scrollandCollapse}
          scrollCollapse={collapse}
          html={html}
          articleHeading={articleHeading}
        />
      </div>

      <Mainfooter />
    </>
  )
}

export default Cateform

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        categoryName
        title
        articleHeading
        categoryID
        featuredImage {
          childImageSharp {
            gatsbyImageData(
              width: 350
              quality: 90
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`
